<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >

          
            <div class="d-flex align-items-center justify-content-end">

              <!-- Saldo -->
              <b-form-group
              >
                <b-form-input
                  id="pesquisar"
                  v-model="pesquisar"
                  name="pesquisar"
                  autocomplete="off"
                />
              </b-form-group>
              <!-- Saldo -->

              <!-- <b-button
                variant="primary"
                @click="aprovacoesLista()"
              >
                <span class="text-nowrap">Pesquisar</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="usuarios"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Sem resultados"
        :sort-desc.sync="isSortDirDesc"
        :busy="carregamentoApi"
      >


        <!-- Column: User -->
        <template #cell(usuarioId)="data">
          <b-media vertical-align="center">
            <b-link
            :to="{ name: 'usuarios-compositor-editar', params: { id: data.item.usuarioId } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.usuarioId }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(statusAprovacao)="data">
          <b-badge
            pill
            :variant="`light-${statusAprovacaoVariant(data.item.statusAprovacao)}`"
            class="text-capitalize"
          >
            {{ statusAprovacaoLabel(data.item.statusAprovacao) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item :to="{ name: 'apps-users-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item :to="{ name: 'usuarios-compositor-editar', params: { id: data.item.usuarioId } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item>
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

        <template #table-busy>
          <div class="text-center">
            <b-spinner
                small
                label="Carregando"
                class="text-primary"
            />
          </div> 
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsuarios"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BSpinner,BCard, BRow, BCol, BFormGroup, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    BSpinner,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {
      usuarios: [],
      tableColumns: [
        { key: 'usuarioId', label: 'ID', sortable: true },
        { key: 'nome', label: 'Nome', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'cpf', label: 'CPF', sortable: true },
        { key: 'saldo', label: 'Saldo', sortable: true },
        { key: 'actions', label: '' },
      ],
      perPage: 25,
      currentPage: 1,
      totalAprovacoes: 0,
      perPageOptions: [10, 25, 50, 100],
      pesquisar: null,
      sortBy: 'usuarioId',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,
      totalUsuarios: null
    }
  },
  created() {
    this.usuariosCompositoresLista()
  },
  watch: {

    pesquisar(newSearchTerm, oldSearchTerm) {
      // Verifica se o novo valor de searchTerm tem pelo menos 5 caracteres
      if (newSearchTerm.length >= 5 || newSearchTerm.length == 0) {
        
        // Chama a função de pesquisa apenas se o comprimento de searchTerm for pelo menos 5
        this.usuariosCompositoresLista()
      }
    }

  },
  methods: {

    // CARREGAR APROVAÇÕES

    usuariosCompositoresLista() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      // console.log( paginaAtual );

      useJwt.usuariosCompositoresLista({
        page: paginaAtual,
        size: this.perPage,
        sort: 'ASC',
        pesquisar: this.pesquisar,
      })
        .then(response => {

          this.usuarios = response.data.content
          this.totalUsuarios = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

    // MUDAR PÁGINA

    mudarPagina() {
      this.usuariosCompositoresLista()
    },

    // MUDAR PÁGINA

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
